@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap");
html {
  min-height: 100%;
  overflow: hidden;
  overflow-y: initial;
  &.flow {
    overflow: hidden !important;
    body {
      overflow: hidden !important;
    }
  }
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 10px;
  @media (max-width: 1728px) {
    font-size: 10px;
  }
  @media (max-width: 1680px) {
    font-size: 9.722222222222221px;
  }
  @media (max-width: 1600px) {
    font-size: 9.25925925925926px;
  }
  @media (max-width: 1440px) {
    font-size: 8.333333333333334px;
  }
  @media (max-width: 1400px) {
    font-size: 8.101851851851853px;
  }
  @media (max-width: 1368px) {
    font-size: 7.916666666666666px;
  }
  @media (max-width: 1366px) {
    font-size: 7.9050925925925934px;
  }
  @media (max-width: 1300px) {
    font-size: 7.523148148148148px;
  }
  @media (max-width: 1280px) {
    font-size: 7.4074074074074066px;
  }
  @media (max-width: 1208px) {
    font-size: 6.9907407407407405px;
  }
  @media (max-width: 1152px) {
    font-size: 6.666666666666666px;
  }
  @media (max-width: 1024px) {
    font-size: 5.925925925925926px;
  }
  &::-webkit-scrollbar {
    width: 0;
  }
}

body {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-size: 1.4rem;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #333333;
  margin: 0;
  padding: 0;
  line-height: 1.6;
  word-break: break-word;
  background-color: #f6f6f6;
  > main {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  @at-root {
    section {
      position: relative;
    }
  }
}

img {
  width: 100%;
  display: block;
}

h6,
.h6 {
  &.require {
    &::after {
      content: "*";
      color: #312948;
    }
  }
}

#side_link {
  > a {
    text-decoration: none;
    &:active {
      text-decoration: none;
    }
    &.active {
      li {
        > div {
          background: #2165a2 !important;
          border-radius: 1rem;
        }
      }
    }
  }
}

// .react-datepicker-wrapper {
//   max-width: 16rem;
//   height: 4.6rem;
//   margin-left: 1.5rem;
//   display: flex;
//   align-items: center;
//   border: 0.2rem solid #202020;
//   filter: drop-shadow(0px 14.8309px 22.6367px rgba(62, 53, 120, 0.14));
//   border-radius: 0.7rem;
//   overflow: hidden;
//   .react-datepicker__input-container {
//     height: 100%;
//   }
//   input {
//     cursor: pointer;
//     height: 100%;
//     max-width: 16rem;
//     text-align: center;
//     border: none;
//     background-color: transparent;
//     font-weight: 500;
//     font-size: 1.4rem;
//     line-height: 2.1rem;
//     color: #202020;
//     &:focus-visible {
//       outline: none;
//     }
//   }
// }

.notify_cont {
  min-height: calc(100vh - 22.25rem);
  overflow-y: auto;
}

.modal-inner-hide {
  &::-webkit-scrollbar {
    display: none;
  }
}

.list-inner-hide {
  &::-webkit-scrollbar {
    width: 0.6rem;
  }
  &::-webkit-scrollbar-thumb {
    background: #333333;
    border-radius: 4.2rem;
  }
}

.MuiDrawer-paper {
  background: #333333 !important;
}
.rbc-header {
  margin-top: 1rem !important;
  border-bottom: none !important;
  font-size: 2.4rem !important;
  color: #2165a2 !important;
  font-weight: 500 !important;
  text-transform: uppercase !important;
  //padding:0px 0 100px 0px;
}

.rbc-button-link {
  display: block !important;
  margin: 0 auto !important;
  margin-top: 1rem !important;
  text-align: center !important;
  color: #828282 !important;
  font-size: 2rem !important;
  font-weight: 500 !important;
}

.rbc-today {
  background-color: transparent !important;
}
.rbc-current {
  .rbc-button-link {
    width: 3.8rem !important;
    min-width: 3.8rem !important;
    height: 3.8rem !important;
    overflow: hidden !important;
    background-color: #2165a2 !important;
    border-radius: 50% !important;
    color: white !important;
  }
}
.rbc-event {
  background-color: #2165a2 !important;
  // width: 16.2rem !important;
  // height: 4.5rem !important;
  margin: 0 auto !important;
  margin-top: 0.6rem !important;
  padding-left: 4.5rem !important;
  position: relative;
  &::before {
    content: "";
    background-image: url("../images/CallIcon.svg");
    background-repeat: no-repeat;
    background-size: 3rem 3rem;
    background-position: left center;
    position: absolute;
    top: 0.8rem;
    left: 0.8rem;
    z-index: 99;
    width: 3rem;
    height: 3rem;
  }
}
.rbc-event-content {
  text-align: center !important;
  font-size: 1.8rem !important;
  color: white;
  font-weight: 500 !important;
}

//apparel card Description

.description {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rbc-month-view {
  border-color: #19236c !important;
  border-radius: 1.23547rem;
  overflow: hidden;
}

.scroll-bar::-webkit-scrollbar {
  width: 15px;
}

#menu-users .MuiPaper-root,
#menu- .MuiPaper-root,
#menu-receiverId,
#menu-coach{
  max-height: 250px !important;
}
.css-1tanhft-MuiAutocomplete-listbox .MuiAutocomplete-option{
  font-size: 15px;
}