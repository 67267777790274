.react-datepicker {
    font-family: unset;
    font-size: 2rem;
  }

  .react-datepicker-wrapper{
    width: 100%;
  }

  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    display: block;
  }

  .react-datepicker__input-container {
    font-size: 1.8rem;
    font-weight: 500;
    height: 5rem;
    /* border-radius: 1rem; */
    border: 1px solid;
    border-color: white;
    overflow: hidden;
  }

  .react-datepicker__input-container:hover {
    border-color: hsl(0,0%,70%);
  }
  .react-datepicker__input-container:focus-within {
    z-index: 1;
    border-color: #3182ce;
    box-shadow: 0 0 0 1px #3182ce;
  }

  .react-datepicker__current-month{
    font-size: 1.6rem !important;
  }

  .react-datepicker__day-name {
    width: 30px;
    font-size: 1.6rem;
  }

  .react-datepicker__day{
    width: 30px;
    padding: 8px 0;
  }

  .react-datepicker__input-container > input {
    width: 100%;
    height: 100%;
    outline: 0;
    padding: 0 1.4rem;
  }

  .react-datepicker__input-container > input:focus{
    box-shadow: none;
    border-color: #fff;
  }

  .react-datepicker__input-container:focus-within {
    box-shadow: none;
    border-color: #fff;
  }

  .react-datepicker__input-container:hover {
    box-shadow: none;
    border-color: #fff;
  }

  .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 90px;
  }

  .react-datepicker__navigation--previous,
  .react-datepicker__navigation--next {
    /* height: 8px; */
    top: 9px
  }

  .react-datepicker__navigation--previous {
    border-right-color: #cbd5e0;
  }

  .react-datepicker__navigation--previous:hover {
    border-right-color: #a0aec0;
  }

  .react-datepicker__navigation--next {
    border-left-color: #cbd5e0;

  }

  .react-datepicker__navigation--next:hover {
    border-left-color: #a0aec0;
  }

  .react-datepicker__header {
    background: #f7fafc;
  }

  .react-datepicker__header,
  .react-datepicker__time-container {
    border-color: #E2E8F0;
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    font-size: inherit;
    font-weight: 600;
  }

  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
    margin: 0 1px 0 0;
    height: auto;
    padding: 7px 10px;
  }

  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
    background: #cde3fa;
  }

  .react-datepicker__day:hover {
    background: #cde3fa;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    background: #003e7e;
    font-weight: normal;
  }

  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
    background: #2a69ac;
  }

  .react-datepicker__day--selected:hover{
    background: #003e7e;
  }

  .react-datepicker__close-icon::after {
    background-color: unset;
    border-radius: unset;
    font-size: 1.5rem;
    font-weight: bold;
    color: hsl(0,0%,80%);
    height: 20px;
    width: 20px;
  }

  .react-datepicker__close-icon::after:hover {
    color: hsl(0,0%,70%)
  }

  .react-datepicker__day-name {
    font-weight: 600;
  }

  .react-datepicker__current-month{
    font-size: 1.1rem;
    color: #002b4f;
  }

  .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
    margin-left: -30px;
  }