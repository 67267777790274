.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.checkbox {
  display: block;
  position: relative;
  cursor: pointer;
}

.checkbox input {
  display: none;
}

.checkbox__indicator {
  height: 3rem;
  width: 3rem;
  background: white;
  border: 0.2rem solid #2165a2;
  border-radius: 50%;
  transition: 125ms ease-out;
}

.checkbox__indicator:disabled {
  border: 0.2rem solid #2165a2;
}

.checkbox__indicator:after {
  content: "";
  position: absolute;
  left: 0.8rem;
  top: 1.5rem;
  width: 0.7rem;
  height: 1.3rem;
  transform: scaleX(-1) rotate(135deg);
  transform-origin: left top;
  border-right: 0.3rem solid white;
  border-top: 3rem solid white;
  display: none;
}

.checkbox input:checked ~ .checkbox__indicator {
  background: #2165a2;
}

.checkbox input:disabled ~ .checkbox__indicator {
  background: #2165a2;
  border: 0.2rem solid #2165a2;
}

.checkbox input:checked ~ .checkbox__indicator:after {
  -webkit-animation: check 125ms;
  animation: check 125ms;
  border-top: 0.3rem solid white;
  border-right: 0.3rem solid white;
  display: block;
}

.full_name {
  line-height: 2.2rem;
  height: 4.4rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.form_dot {
  position: relative;
}

.form_dot:last-child {
  margin: 0;
}

.form_dot::before {
  content: "";
  width: 8px;
  min-width: 8px;
  height: 8px;
  background: #282c34;
  position: absolute;
  bottom: -5px;
  left: 50%;
  z-index: 28;
  border-radius: 100%;
}

@media (min-width: 600px) {
  .form_dot::before {
    bottom: 0;
    top: 36%;
    left: -5px;
  }
}

.form_dot.active::before {
  background: #299aff;
}

.tabs_blk::-webkit-scrollbar {
  display: none;
}

/* Notes */
#notes li::before {
  content: "•";
  color: #2165A2;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
  flex-shrink: 0;
}