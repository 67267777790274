/*
|----------------------------------------------------------------------
|       Import Files
|----------------------------------------------------------------------
*/

.MuiDataGrid-root .MuiDataGrid-columnHeader,
.MuiDataGrid-root .MuiDataGrid-cell {
  outline: none !important;
}

@import "../src/assets/scss/_reset";
